import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Linkedin, Mail, Book, Briefcase, Award, Users,  Code, Folder, Menu, X, FileText, Calendar, Github, Pen, User, SquareArrowOutUpRight } from 'lucide-react';

interface PortfolioData {
  name: string;
  introduction: string[];
  contact: {
    email: string;
    linkedin: string;
    github: string;
  };
  education: {
    degree: string;
    university: string;
    duration: string;
    gpa: string;
    location: string;
    courses: string[];
  };
  certifications: Array<{
    url: string | undefined;
    name: string;
    issuer: string;
    date: string;
  }>;
  skills: {
    [key: string]: string[];
  };
  experience: Array<{
    title: string;
    company: string;
    duration: string;
    project: string;
    description: string[];
  }>;
  projects: Array<{
    name: string;
    technologies: string[];
    date: string;
    description: string[];
    github: string;
  }>;
  smallProjects: Array<{
    technologies: string[];
    github: string | undefined;
    name: string;
    description: string;
    link: string;
  }>;
  honors: Array<{
    title: string;
    position: string;
    date: string;
    description: string;
  }>;
  blogs: Array<{
    title: string;
    date: string;
    summary: string;
    link: string;
  }>;
}

const skillIcons: { [key: string]: React.ReactNode } = {
  Java: <i className="devicon-java-plain colored"></i>,
  "C#": <i className="devicon-csharp-plain colored"></i>,
  Python: <i className="devicon-python-plain colored"></i>,
  JavaScript: <i className="devicon-javascript-plain colored"></i>,
  TypeScript: <i className="devicon-typescript-plain colored"></i>,
  SQL: <i className="devicon-mysql-plain colored"></i>,
  PHP: <i className="devicon-php-plain colored"></i>,
  Dart: <i className="devicon-dart-plain colored"></i>,
  HTML: <i className="devicon-html5-plain colored"></i>,
  CSS: <i className="devicon-css3-plain colored"></i>,
  React: <i className="devicon-react-original colored"></i>,
  "ASP.NET": <i className="devicon-dot-net-plain colored"></i>,
  Angular: <i className="devicon-angularjs-plain colored"></i>,
  Spring: <i className="devicon-spring-plain colored"></i>,
  "Node.js": <i className="devicon-nodejs-plain colored"></i>,
  Flutter: <i className="devicon-flutter-plain colored"></i>,
  Git: <i className="devicon-git-plain colored"></i>,
  Docker: <i className="devicon-docker-plain colored"></i>,
  AWS: <i className="devicon-amazonwebservices-original colored"></i>,
  Linux: <i className="devicon-linux-plain colored"></i>,
};

export default function EnhancedAnimatedPortfolioWithFixedCertifications() {
  const [data, setData] = useState<PortfolioData | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then((jsonData: PortfolioData) => setData(jsonData))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <motion.div
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1
          }}
          className="w-16 h-16 bg-red-500"
        />
      </div>
    );
  }
  const keywords = [
    "Full-Stack Development", "machine learning", "cybersecurity", "Java", "C#", "Python", 
    "Angular", "React", "ASP.NET", "Secretary", "Deep Learning", "Google Developer Group"
  ];
  

  function highlightKeywords(text: string) {
    const regex = new RegExp(`(${keywords.join("|")})`, "gi"); // Case-insensitive search for keywords
    
    return text.split(regex).map((segment, index) => {
      if (keywords.some(keyword => keyword.toLowerCase() === segment.toLowerCase())) {
        return (
          <span key={index} className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-sm">
            {segment}
          </span>
        );
      }
      return segment;
    });
  }

  

  return (
    <div className="min-h-screen bg-gray-900 text-white relative overflow-hidden">
      <AnimatedBackground />

      <div className="fixed left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-r-lg shadow-lg z-20">
        <div className="flex flex-col space-y-6">
          <a href={`mailto:${data.contact.email}`} className="text-white hover:text-red-300 transition-colors" aria-label="Email">
            <Mail size={24} />
          </a>
          <a href={`https://${data.contact.linkedin}`} target="_blank" rel="noopener noreferrer" className="text-white hover:text-red-300 transition-colors" aria-label="LinkedIn">
            <Linkedin size={24} />
          </a>
          <a href={`https://${data.contact.github}`} target="_blank" rel="noopener noreferrer" className="text-white hover:text-red-300 transition-colors" aria-label="Website">
            <Github size={24} />
          </a>
        </div>
      </div>

      <header className="bg-red-900 py-6 sticky top-0 z-10">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <motion.h1 
            className="text-4xl font-bold"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {data.name}
          </motion.h1>
          <nav className="hidden md:flex space-x-4">
            {['Education', 'Skills', 'Experience', 'Projects', 'Blogs'].map((item) => (
              <button
                key={item}
                onClick={() => scrollToSection(item.toLowerCase())}
                className="text-white hover:text-red-300 transition-colors"
              >
                {item}
              </button>
            ))}
          </nav>
          <div className="flex items-center space-x-4">
            <a
              href="https://drive.google.com/file/d/1JvIv1UNn45sKN64_Qt25pSlWrfNGO8nO/view?usp=drive_link"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center transition-colors"
            >
              <FileText className="mr-2" size={18} />
              Resume
            </a>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="md:hidden text-white hover:text-red-300 transition-colors"
              aria-label={menuOpen ? "Close menu" : "Open menu"}
            >
              {menuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </header>

      {menuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-red-800 py-2 px-4 md:hidden"
        >
          {['Education', 'Skills', 'Experience', 'Projects', 'Blogs'].map((item) => (
            <button
              key={item}
              onClick={() => scrollToSection(item.toLowerCase())}
              className="block w-full text-left py-2 text-white hover:text-red-300 transition-colors"
            >
              {item}
            </button>
          ))}
        </motion.div>
      )}

      <main className="container mx-auto px-4 py-8 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <AnimatedSection id="introduction" title="Introduction" icon={<User size={24} />}>
          <div>
            {data.introduction.map((line, index) => (
              <React.Fragment key={index}>
                <p className="text-gray-300">
                  {highlightKeywords(line)}
                </p>
                <br />
              </React.Fragment>
            ))}
          </div>
        </AnimatedSection>

          <AnimatedSection id="education" title="Education" icon={<Book size={24} />}>
            <p className="font-semibold">{data.education.degree}</p>
            <div className="flex flex-wrap gap-2 mb-4 mt-2">
            <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{data.education.university}</p>
            <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{data.education.gpa}</p>
            <p className="bg-red-900 w-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs"> Honors </p>
            </div>
            
            <p className="flex items-center text-sm text-gray-400 mb-4">
              <Calendar size={14} className="mr-2" />
              {data.education.duration}</p>
            <p className="mt-2 font-semibold">Relevant Courses:</p>
            <ul className="list-disc list-inside">
              {data.education.courses.map((course, index) => (
                <motion.li 
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="text-red-400"
                >
                  <motion.span
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 1, repeat: Infinity }}
                  >
                    {course}
                  </motion.span>
                </motion.li>
              ))}
            </ul>
          </AnimatedSection>
        </div>

        

        <AnimatedSection id="skills" title="Skills" icon={<Code size={24} />}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.entries(data.skills).map(([category, skills], index) => (
              <motion.div 
                key={category} 
                className="bg-gray-800 bg-opacity-50 p-4 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3 className="font-semibold capitalize mb-2">{category}</h3>
                <div className="flex flex-wrap gap-2">
                  {skills.map((skill, i) => (
                    <motion.div
                      key={i}
                      className="flex items-center bg-gray-700 bg-opacity-50 px-2 py-1 rounded-full text-sm"
                      whileHover={{ scale: 1.05 }}
                    >
                      <span className="mr-1 text-xl">{skillIcons[skill] || <Code size={16} />}</span>
                      <span>{skill}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </AnimatedSection>

        <AnimatedSection id="experience" title="Experience" icon={<Briefcase size={24} />}>
          {data.experience.map((exp, index) => (
            <motion.div 
              key={index} 
              className="mb-6 bg-gray-800 bg-opacity-50 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h3 className="font-semibold text-xl">{exp.title}</h3>
              
              <div className="flex flex-wrap justify-between mb-4">
              <p className="bg-red-900 mt-2 w-max h-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{exp.company}</p>
              
              <p className="flex items-center text-sm text-gray-400 mb-4">
                <Calendar size={14} className="mr-2" />
                {exp.duration}
              </p>
              </div>
              <p className="italic text-xs"> What I worked on... </p>
              <p className="font-semibold mt-2 text-red-400">{exp.project}</p>
              <br/>
              <p className="italic text-xs "> What I did...</p>
              <ul className="list-disc list-inside mt-2">
                {exp.description.map((desc, i) => (
                  
                  <li key={i} className="text-gray-300 mt-2">{desc}</li>
                  
                ))}
              </ul>
            </motion.div>
          ))}
        </AnimatedSection>

        <AnimatedSection id="projects" title="Projects" icon={<Folder size={24} />}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data.projects.map((project, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-800 bg-opacity-100 rounded-lg overflow-hidden shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="p-6">
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-semibold text-xl">{project.name}</h3>
                    <a href={project.github} target="_blank" rel="noopener noreferrer" 
                    className="text-red-400 hover:text-red-300">
                      <Github size={24} />
                    </a>
                  </div>
                  <div className="flex items-center text-sm text-gray-400 mb-4">
                    <Calendar size={14} className="mr-2" />
                    {project.date}
                  </div>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.technologies.map((tech, i) => (
                      <span key={i} className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">
                        {tech}
                      </span>
                    ))}
                  </div>
                  <p className="italic text-xs mb-3"> What I did ...</p>
                  
                  <ul className="list-disc list-inside text-gray-300">
                    {project.description.map((desc, i) => (
                  
                      <li key={i} className="mb-1 mt-2">{desc}</li>
                      
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
          {data.smallProjects && data.smallProjects.length > 0 && (
            <>
              <h3 className="text-2xl font-bold mt-8 mb-4">Other Projects</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {data.smallProjects.map((project, index) => (
                  <motion.div
                    key={index}
                    className="bg-gray-800 bg-opacity-50 p-4 rounded-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0  }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-semibold text-xl">{project.name}</h3>
                    <a href={project.github} target="_blank" rel="noopener noreferrer" 
                    className="text-red-400 hover:text-red-300">
                      <Github size={24} />
                    </a>
                  </div>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.technologies.map((tech, i) => (
                      <span key={i} className="bg-red-900 bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">
                        {tech}
                      </span>
                    ))}
                  </div>
                    
                    <p className="italic text-xs"> What this is...</p>
                    <br/>
                    <p className="text-gray-300 mb-2">{project.description}</p>
                  </motion.div>
                ))}
              </div>
            </>
          )}
        </AnimatedSection>

        {data.blogs && data.blogs.length > 0 && (
          <AnimatedSection id="blogs" title="Blog Posts" icon={<Pen size={24} />}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {data.blogs.map((blog, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-800 bg-opacity-50 rounded-lg overflow-hidden shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className="p-6">
                    <h3 className="font-semibold text-xl mb-2">{blog.title}</h3>
                    <p className="text-sm text-gray-400 mb-4">{blog.date}</p>
                    <p className="text-gray-300 mb-4">{blog.summary}</p>
                    <a href={blog.link} target="_blank" rel="noopener noreferrer" className="text-red-400 hover:text-red-300">
                      Read More
                    </a>
                  </div>
                </motion.div>
              ))}
            </div>
          </AnimatedSection>
        )}
        <AnimatedSection id="certifications" title="Certifications" icon={<Award size={24} />}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data.certifications.map((cert, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 bg-opacity-50 p-4 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex justify-between items-start mb-2">
                <h3 className="font-semibold text-lg">{cert.name}</h3>
                <a href={cert.url} target="_blank" rel="noopener noreferrer" className="text-red-400 hover:text-red-300">
                  <SquareArrowOutUpRight size={24} />
                </a>
                </div>
                <div className="flex flex-wrap justify-between mb-1">
                <p className="bg-red-900 w-max h-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{cert.issuer}</p>
                
                <p className="flex items-center text-sm text-gray-400 mb-2">
                  <Calendar size={14} className="mr-2" />
                  {cert.date}

                </p>
                </div>
                
              </motion.div>
            ))}
          </div>
        </AnimatedSection>
        <AnimatedSection id="honors" title="Activities" icon={<Users size={24} />}>
          {data.honors.map((honor, index) => (
            <motion.div 
              key={index} 
              className="mb-4 bg-gray-800 bg-opacity-50 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}

            >
              <h3 className="font-semibold text-xl">{honor.title}</h3>
              <div className="flex flex-wrap mt-2 justify-between">
              <p className="bg-red-900 w-max h-max bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">{honor.position} </p>
              <p className="flex items-center text-sm text-gray-400 mb-4">
                <Calendar size={14} className="mr-2" />
                {honor.date}
              </p>
              </div>
              <p className="italic text-xs mb-2">What I do...</p>
              <p className="text-gray-300">{honor.description}</p>
            </motion.div>
          ))}
        </AnimatedSection>
      </main>
    </div>
  );
}

function AnimatedBackground() {
  return (
    <div className="fixed inset-0 z-0">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMjIyIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiMzMzMiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-10"></div>
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute bg-red-500 rounded-full opacity-20"
            style={{
              width: Math.random() * 100 + 50,
              height: Math.random() * 100 + 50,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              x: [0, Math.random() * 400 - 200],
              y: [0, Math.random() * 400 - 200],
            }}
            transition={{
              duration: Math.random() * 10 + 10,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
        ))}
      </div>
    </div>
  );
}

interface AnimatedSectionProps {
  id: string;
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

function AnimatedSection({ id, title, icon, children }: AnimatedSectionProps) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.1 });

  return (
    <motion.section 
      id={id}
      ref={ref}
      className="mb-16"
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center bg-red-900 bg-opacity-50 p-4 rounded-t-lg">
        <div className="mr-2 text-white">{icon}</div>
        <h2 className="text-2xl font-bold text-white">{title}</h2>
      </div>
      <div className="mt-4 bg-gray-800 bg-opacity-50 p-4 rounded-b-lg">
        {children}
      </div>
    </motion.section>
  );
}